html {
  font-family: sans-serif; // 1
  text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: $root-font-size !important;
  font-weight: $font-weight-base;
  font-family: $font-family-sans-serif;

  // Tablet mode
  @include media-breakpoint-down(lg) {
    font-size: $root-font-size-lg !important;
  }

  // Mobile mode
  @include media-breakpoint-down(md) {
    font-size: $root-font-size-md !important;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none !important;
  }
}

body {
  display: flex;
  flex-direction: column;
  color: $body-color;
  // border: solid 20px red !important;
}

body .capitalize-text{
  text-transform: capitalize;
}
::-webkit-input-placeholder { /* WebKit browsers */
  text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  text-transform: none;
}
::placeholder { /* Recent browsers */
  text-transform: none;
}
// Angular integration
router-outlet {
  display: none;
}

canvas {
  user-select: none;
}

.modal-historial{
  width: '1200px' !important;
}

// Se creó la clase padre salesPageView para aislar el CSS de las otras vistas
.salesPageView .header-grid-wrapper {
  align-items: center;
  margin: 0;
}
.salesPageView .header-grid-wrapper .buttons-wrapper {
  text-align: right;
  padding-right: 0;
}
.salesPageView .header-grid-wrapper .buttons-wrapper {
  text-align: right;
}
.salesPageView .header-grid-wrapper .buttons-wrapper .btn {
  width: 100%;
  max-width: 120px;
}
.salesPageView .header-grid-wrapper .buttons-wrapper .btn:first-child {
  margin-right: 10px;
}
.btn .svg-icon {
  margin-right: 5px;
}
.btn.btn-light-success:hover .svg-icon.svg-icon-excel svg path.st0,
.btn.btn-light-success:hover .svg-icon.svg-icon-excel svg path.st1 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1;
  stroke-miterlimit: 10;
}
.btn.btn-light-success:hover .svg-icon.svg-icon-excel svg path.st2 {
  fill: #ffffff;
}

.salesPageView .card .card-header {
  margin-top: 10px;
  height: 90px;
}
.salesPageView .fv-plugins-message-container {
  position:absolute;
  display: block;
  z-index: 0;
  margin-top: 5px;
  width: 210px !important;
}
.salesPageView .fv-plugins-message-container span {
  display: block;
  margin-left: -16px;
  font-size: 12px;
  line-height: normal;
}

// Se creó la clase padre birthdayPageView para aislar el CSS de las otras vistas
.salesPageView.birthdayPageView .header-grid-wrapper .birthday_select {
  display: inline-block;
}

.card .d-flex.align-items-center {
  min-height: 200px !important;
}

// Estilos del logo del Login Admin
.login-logo {
  margin-top: 20px;
  height: 170px;
}